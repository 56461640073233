// @flow
import { useFela, } from 'react-fela';
import React, { type Node, } from 'react';

import type { ListViewProps, } from '../../../../flowTypes/ListViewProps';
import type { TeaserDataType, } from '../../../../flowTypes/TeaserDataType';
import Footer from '../../../TeaserFooter/TeaserFooter';
import Image from '../../../Image/Image';
import ListViewHeader from '../../../ListViewHeader/ListViewHeader';
import Section from '../../../AutoLevels/Section';
import Teaser from '../../../Teaser/NewTeaser';
import TeaserHeader from '../../../TeaserHeader/TeaserHeader';
import TeaserMedia from '../../../TeaserMedia/NewTeaserMedia';
import setBiAction from '../../../../utils/setBiAction';
import { useIsBot, } from '../../../../hooks/useIsBot';
import useDarkModeChecker from '../../../../hooks/useDarkModeChecker';

BenderBigView.defaultProps = {
  isLazyloadImages: true,
  biAction: null,
  gaAction: null,
};
export default function BenderBigView({
  list,
  isLazyloadImages,
  gaAction,
  biAction,
  updatePersonalList,
  inView,
}: ListViewProps): Node {
  const { theme, css, } = useFela();
  const { isBot, } = useIsBot();
  const isDarkMode = useDarkModeChecker();

  const backgroundColor = isDarkMode ? 'transparent' : 'white';

  const { items, title, } = list;

  const horizontalPadding = 2;
  if (isBot || !items || !items.length) return null;


  function itemRenderer(item, i) {
    return item && i < 18
      ? (
        <BenderBigItem
          item={item}
          biAction={setBiAction(i, item, biAction)}
          isLazyloadImages
          teaserIndex={i}
          backgroundColor={backgroundColor}
        />
      )
      : null;
  }

  return (
    <Section
      className={css({
        backgroundColor: theme.color(backgroundColor),
        display: 'grid',
        gridGap: '1rem 4rem',
        padding: `0 ${horizontalPadding}rem 2rem`,
        width: '100%',
        extend: [
          theme.mq({ until: 's', }, { display: 'none', }),
          theme.mq({ from: 's', until: 'l', }, { gridTemplateColumns: 'repeat(3, 1fr)', }),
          theme.mq({ from: 'l', until: 'xl', }, { gridTemplateColumns: 'repeat(4, 1fr)', }),
          theme.mq({ from: 'xl', }, { gridTemplateColumns: 'repeat(6, 1fr)', }),
        ],
      })}
      data-test="benderBig"
    >
      {title ? (
        <ListViewHeader
          disableGridArea
          isHorizontal
          biAction={biAction}
          miscStyles={{
            gridColumn: '1/-1',
            paddingInlineStart: `${horizontalPadding}rem`,
            marginInlineEnd: `-${horizontalPadding}rem`,
            marginInlineStart: `-${horizontalPadding}rem`,
          }}
          {...list}
        />
      ) : null}
      <Section isFragment>{items.map(itemRenderer)}</Section>
    </Section>
  );
}
type BigItemPropsType = {
  item: TeaserDataType,
  biAction: ?() => void,
  isLazyloadImages: boolean,
  teaserIndex: number,
  backgroundColor: 'white' | 'transparent',
};

BenderBigItem.defaultProps = {
  backgroundColor: 'white',
};

function BenderBigItem({
  item,
  biAction,
  isLazyloadImages,
  teaserIndex,
  backgroundColor,
}: BigItemPropsType): Node {
  const { theme, } = useFela();

  const image = item?.mobileImage || item?.image;

  return (
    <Teaser
      key={item.contentId}
      areasTemplate='"media" "content" "footer" "."'
      rowTemplate="auto 1fr auto 0"
      rowGap="1rem"
      backgroundColor={backgroundColor}
      miscStyles={teaserIndex < 3 ? null : {
        borderTop: teaserIndex > 5
          ? [ '1px', 1, 'solid', theme.color('neutral', '-5'), ]
          : [
            { until: teaserIndex > 3 ? 'xl' : 'l', value: [ '1px', 1, 'solid', theme.color('neutral', '-5'), ], },
          ],
        display: teaserIndex < 9 ? undefined : [
          { until: teaserIndex > 11 ? 'xl' : 'l', value: 'none', },
        ],
      }}
    >
      {teaserIndex < 6 && (
        <TeaserMedia
          miscStyles={teaserIndex < 3 ? null : {
            display: [
              { until: teaserIndex > 3 ? 'xl' : 'l', value: 'none', },
            ],
          }}
          data={item}
          onClick={biAction}
        >
          <Image
            image={image}
            imgOptions={{ transforms: { aspect: 'vertical', width: '500', }, }}
            lazyLoad={isLazyloadImages}
          />
        </TeaserMedia>
      )}
      <TeaserHeader
        {...item}
        onClick={biAction}
        isGridItem
        kickerMiscStyles={{ fontWeight: 700, }}
      />
      <Footer data={{ ...item, commentsCount: 0, }} showTime={false} showAuthor />
    </Teaser>
  );
}
