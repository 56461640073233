// @flow
/* global window */
import * as React from 'react';
import { BENDER_BIG_LIST_QUERY, } from '@haaretz/graphql';
import BenderView from './BenderBigView.js';
import type { ListDataType, } from '../../../../flowTypes/ListDataType';
import ListWrapper from '../../ListWrapper';

type BenderWrapperProps = {
  updateListDuplication: Function,
  listData: ListDataType,
  isPersonal: boolean,
}
export default function BenderBigWrapper(props: BenderWrapperProps): React.Node {
  return (
    <ListWrapper {...props} query={BENDER_BIG_LIST_QUERY} view="BenderBig">
      {dataProps => <BenderView {...dataProps} />}
    </ListWrapper>
  );
}
